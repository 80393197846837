var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-1"},[_c('v-row',{staticClass:"mx-3 mt-3"},[_c('v-btn',{attrs:{"color":"blue lighten-2","outlined":"outlined","block":"block","dark":"dark"},on:{"click":function($event){return _vm.$emit('closeResult')}}},[_vm._v(" 元のページ戻る ")])],1),_c('v-row',{staticClass:"justify-space-between ma-3"},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLogin),expression:"isLogin"}],attrs:{"color":"blue lighten-2","block":"block","dark":"dark","loading":_vm.loading},on:{"click":_vm.sendResult}},[_vm._v(" 結果を送信して終了する ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLogin),expression:"!isLogin"}],attrs:{"color":"blue lighten-2","block":"block","dark":"dark"},on:{"click":_vm.sendResult}},[_vm._v(" 終了する ")])],1),_c('result-chart',{attrs:{"questions":_vm.questions}}),_c('v-card',{staticClass:"ma-3"},[_c('v-row',{staticClass:"no-gutters justify-center"},[_c('div',{staticClass:"pa-2 text-body-2"},[_vm._v(" 問題をタップすると該当問題に移動します ")])]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.questions,"dense":"dense","disable-pagination":"disable-pagination","hide-default-footer":"hide-default-footer"},on:{"click:row":_vm.jumpPage},scopedSlots:_vm._u([{key:"item.flg",fn:function(ref){
var item = ref.item;
return [(item.flg)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-bookmark-plus ")]):_vm._e()]}},{key:"item.laterCheck",fn:function(ref){
var item = ref.item;
return [(item.laterCheck)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v(" mdi-flag ")]):_vm._e()]}},{key:"item.correct",fn:function(ref){
var item = ref.item;
return [(item.correct === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-circle-outline ")]):(item.correct === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")]):_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" mdi-minus ")])]}}])})],1),_c('v-snackbar',{attrs:{"bottom":"bottom","color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',_vm._b({attrs:{"dark":"dark"},on:{"click":function($event){_vm.apiFailed = false}}},'v-icon',attrs,false),[_vm._v(" mdi-close ")])]}}]),model:{value:(_vm.apiFailed),callback:function ($$v) {_vm.apiFailed=$$v},expression:"apiFailed"}},[_vm._v(" データの登録に失敗しました、再度お試しください ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }