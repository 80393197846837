<template>
  <v-sheet class="pa-1">
    <v-row class="mx-3 mt-3">
      <v-btn
        color="blue lighten-2"
        outlined="outlined"
        block="block"
        dark="dark"
        @click="$emit('closeResult')"
      >
        元のページ戻る
      </v-btn>
    </v-row>
    <v-row class="justify-space-between ma-3">
      <v-btn
        v-show="isLogin"
        color="blue lighten-2"
        block="block"
        dark="dark"
        :loading="loading"
        @click="sendResult"
      >
        結果を送信して終了する
      </v-btn>
      <v-btn
        v-show="!isLogin"
        color="blue lighten-2"
        block="block"
        dark="dark"
        @click="sendResult"
      >
        終了する
      </v-btn>
    </v-row>
    <result-chart :questions="questions" />
    <v-card class="ma-3">
      <v-row class="no-gutters justify-center">
        <div class="pa-2 text-body-2">
          問題をタップすると該当問題に移動します
        </div>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="questions"
        dense="dense"
        disable-pagination="disable-pagination"
        hide-default-footer="hide-default-footer"
        @click:row="jumpPage"
      >
        <template v-slot:item.flg="{item}">
          <v-icon
            v-if="item.flg"
            color="green"
          >
            mdi-bookmark-plus
          </v-icon>
        </template>
        <template v-slot:item.laterCheck="{item}">
          <v-icon
            v-if="item.laterCheck"
            color="orange"
          >
            mdi-flag
          </v-icon>
        </template>
        <template v-slot:item.correct="{item}">
          <v-icon
            v-if="item.correct === true"
            color="green"
          >
            mdi-circle-outline
          </v-icon>
          <v-icon
            v-else-if="item.correct === false"
            color="red"
          >
            mdi-close
          </v-icon>
          <v-icon
            v-else
            color="grey"
          >
            mdi-minus
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      v-model="apiFailed"
      bottom="bottom"
      color="error"
    >
      データの登録に失敗しました、再度お試しください
      <template v-slot:action="{ attrs }">
        <v-icon
          v-bind="attrs"
          dark="dark"
          @click="apiFailed = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex';
import ResultChart from "@/components/Result/ResultChart";

export default {
  name: "Result",
  components: { ResultChart },
  data() {
    return {
      loading: false,
      apiFailed: false,
    }
  },
  computed: {
    ...mapGetters(["questions", "isLogin", "checkLaterPages", "responseStatus"]),
    headers() {
      return this.$cpaMode ?
        [
          { text: 'No.', value: "page" },
          { text: 'ブックマーク', value: "flg" },
          { text: '正解', value: "correct" },
          { text: '科目', value: "level0" },
          { text: '章', value: "level1" },
          { text: '節', value: "level2" },
        ]
        :
        [
          { text: 'No.', value: "page" },
          { text: 'ブックマーク', value: "flg" },
          { text: '後で確認', value: "laterCheck" },
          { text: '正解', value: "correct" },
          { text: '科目', value: "subject_category" },
          { text: '分類', value: "category" },
          { text: '出典', value: "exam" },
        ]
    }
  },
  methods: {
    async sendResult() {
      this.loading = true
      await this.$store.dispatch('sendResult');
      if (this.responseStatus) {
        await this.$router.push('/');
        this.$store.commit('RESET_QUESTIONS');
      } else {
        this.apiFailed = true;
      }
      this.loading = false
    },
    jumpPage(e) {
      this.$emit('closeResult');
      const currentPage = this.$route.params.page;
      if (Number(currentPage) === Number(e.page)) return;

      this.$router.push({ name: 'question', params: { page: e.page } });
    },
  },
}
</script>

<style scoped>

</style>
